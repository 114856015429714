import { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  getActions,
  getActionsNew,
  getFeedsList
  //getActionUpdates
} from '../../actions/connector';

const MainRequests = ({
  auth: { user, profile },
  getActions,
  getActionsNew,
  getFeedsList
  //getActionUpdates
}) => {
  useEffect(() => {
    if (profile._id) {
      if (profile.connector) {
        getActions();
        getFeedsList();
      }
      if (profile.connectorNew) {
        getActionsNew();
      }

      const interval = setInterval(() => {
        if (window.navigator.onLine) {
          if (profile.connector) {
            getActions();
            getFeedsList();
          }
          if (profile.connectorNew) {
            getActionsNew();
          }
        }
      }, 180000);
      return () => clearInterval(interval);
    }
  }, [
    getActions,
    getActionsNew,
    getFeedsList,
    //getActionUpdates,
    user.role,
    profile._id,
    profile.connector,
    profile.connectorNew
  ]);

  return null;
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, {
  getActions,
  getActionsNew,
  getFeedsList
  // getActionUpdates
})(MainRequests);
